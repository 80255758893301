import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Promo from '../promo/Promo';
import { recipeShape } from './shapes';

if (process.env.CLIENT) {
  require('./FavouriteRecipePromo.scss'); // eslint-disable-line global-require
}

const getButtonProp = state => {
  switch (state) {
    case 'error':
      return {
        class: 'error',
        text: 'Failed',
      };
    case 'waiting':
      return {
        class: 'waiting',
        text: 'Removing',
      };
    default:
      return {
        class: 'remove',
        text: 'Remove',
      };
  }
};

const FavouriteRecipePromo = ({
  recipe,
  shouldShowDeleteBtn,
  buttonState,
  toggleConfirmation,
  deleteFavouriteRecipe,
}) => {
  const promoProps = recipe.isRevoked ? { ...recipe, type: undefined } : { ...recipe };

  return (
    <div className="favourite-recipe-promo">
      <div className="gel-layout gel-layout--flush gel-layout--equal favourite-recipe-promo__promo">
        <div className="gel-layout__item">
          <Promo {...promoProps} />
          {recipe.isRevoked && (
            <div className="favourite-recipe-promo__revoked-overlay">
              <div className="favourite-recipe-promo__revoked-message">No longer available</div>
            </div>
          )}
        </div>
      </div>
      <div className="promo-footer">
        <div
          className={classNames(
            'promo-footer__buttons',
            shouldShowDeleteBtn ? 'promo-footer__buttons--open' : ''
          )}
        >
          <button
            aria-label={`${
              shouldShowDeleteBtn ? 'Close' : 'Open'
            } remove from favourites confirmation draw`}
            className="promo-footer__remove-button"
            onClick={() => toggleConfirmation(recipe.id)}
            type="button"
          >
            <span className="promo-footer__remove-button-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <polygon points="16,2.6 13.4,0 8,5.4 2.6,0 0,2.6 5.4,8 0,13.4 2.6,16 8,10.6 13.4,16 16,13.4 10.6,8" />
              </svg>
            </span>
          </button>
          <button
            aria-label={`Remove ${recipe.title} from your favourites`}
            className={classNames(
              'promo-footer__confirm-button',
              `promo-footer__confirm-button--${getButtonProp(buttonState).class}`,
              'gel-pica-bold'
            )}
            onClick={() => {
              if (buttonState !== 'waiting') deleteFavouriteRecipe(recipe.id);
            }}
            type="button"
          >
            <span>{getButtonProp(buttonState).text}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

FavouriteRecipePromo.propTypes = {
  recipe: PropTypes.shape(recipeShape).isRequired,
  shouldShowDeleteBtn: PropTypes.bool.isRequired,
  buttonState: PropTypes.string,
  toggleConfirmation: PropTypes.func.isRequired,
  deleteFavouriteRecipe: PropTypes.func.isRequired,
};

FavouriteRecipePromo.defaultProps = {
  buttonState: undefined,
};

export default FavouriteRecipePromo;
