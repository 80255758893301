import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FavouriteRecipesCollection from './FavouriteRecipesCollection';
import MetaProperties from '../generic/MetaProperties';
import PageHeader from '../generic/PageHeader';
import Pagination from '../generic/pagination/Pagination';
import FocusTarget from '../generic/FocusTarget';
import { scrollIntoView } from '../generic/scrollIntoView';
import { deleteFavouriteRecipe, getFavouriteRecipes, toggleConfirmation } from './actions';
import { selectTotalPage } from './reducer';
import { recipeShape } from './shapes';

export class Page extends Component {
  focusRef = React.createRef();

  componentDidMount() {
    this.fetchFavourites();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchFavourites();
      scrollIntoView(this.focusRef.current);
      this.focusRef.current.focus();
    }
  }

  fetchFavourites = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page'), 10) || 1;
    this.props.getFavouriteRecipes(page);
  };

  render() {
    const { confirmationList, favouriteRecipes, page, pages, pageState, removeButtonState } =
      this.props;

    return (
      <>
        <MetaProperties title="Your Favourites" />
        <PageHeader title="Your favourite items" />
        <FocusTarget ref={this.focusRef} />
        <FavouriteRecipesCollection
          favouriteRecipes={favouriteRecipes}
          confirmationList={confirmationList}
          pageState={pageState}
          toggleConfirmation={this.props.toggleConfirmation}
          deleteFavouriteRecipe={this.props.deleteFavouriteRecipe}
          removeButtonState={removeButtonState}
        />
        {pages > 1 && (
          <Pagination
            clientSide
            page={page}
            pages={pages}
            buildUrl={p => `/food/my/favourites?page=${p}`}
          />
        )}
      </>
    );
  }
}

Page.defaultProps = {
  page: 1,
  pages: 1,
};

Page.propTypes = {
  confirmationList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  deleteFavouriteRecipe: PropTypes.func.isRequired,
  favouriteRecipes: PropTypes.arrayOf(PropTypes.shape(recipeShape)).isRequired,
  getFavouriteRecipes: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  page: PropTypes.number,
  pages: PropTypes.number,
  pageState: PropTypes.string.isRequired,
  removeButtonState: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  toggleConfirmation: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    confirmationList: state.favouriteReducer.confirmationList,
    contentTitle: state.pageReducer.contentTitle,
    favouriteRecipes: state.favouriteReducer.favouriteRecipes,
    page: state.favouriteReducer.page,
    pages: selectTotalPage(state),
    pageState: state.favouriteReducer.pageState,
    removeButtonState: state.favouriteReducer.removeButtonState,
  }),
  {
    deleteFavouriteRecipe,
    getFavouriteRecipes,
    toggleConfirmation,
  }
)(Page);
