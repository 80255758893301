import React from 'react';
import PropTypes from 'prop-types';
import Collection from '../promo/Collection';
import FavouriteRecipePromo from './FavouriteRecipePromo';
import NoRecipe from './NoRecipe';
import { recipeShape } from './shapes';

const FavouriteRecipesCollection = ({
  favouriteRecipes,
  pageState,
  confirmationList,
  removeButtonState,
  toggleConfirmation,
  deleteFavouriteRecipe,
}) => {
  if (pageState !== 'loaded') {
    return (
      <div className="favourite-recipes-collection gel-wrap">
        <div className="gel-layout">
          <div className="my-favourites-recipes__status-message gel-layout__item">
            {pageState === 'loading'
              ? 'Loading your favourite recipes'
              : 'Unable to load your favourite recipes'}
          </div>
        </div>
      </div>
    );
  }
  return favouriteRecipes.length > 0 ? (
    <Collection
      id="my-favourites-recipes"
      collectionClassName="my-favourites-recipes"
      TitleElement="h1"
    >
      {favouriteRecipes.map(recipe => (
        <div key={recipe.id} className="gel-layout__item gel-1/1 gel-1/3@m gel-1/4@l">
          <FavouriteRecipePromo
            recipe={recipe}
            shouldShowDeleteBtn={confirmationList.some(id => id === recipe.id)}
            buttonState={removeButtonState[recipe.id]}
            toggleConfirmation={toggleConfirmation}
            deleteFavouriteRecipe={deleteFavouriteRecipe}
          />
        </div>
      ))}
    </Collection>
  ) : (
    <NoRecipe />
  );
};

FavouriteRecipesCollection.propTypes = {
  favouriteRecipes: PropTypes.arrayOf(PropTypes.shape(recipeShape)).isRequired,
  pageState: PropTypes.string.isRequired,
  confirmationList: PropTypes.arrayOf(PropTypes.string).isRequired,
  removeButtonState: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  toggleConfirmation: PropTypes.func.isRequired,
  deleteFavouriteRecipe: PropTypes.func.isRequired,
};

export default FavouriteRecipesCollection;
