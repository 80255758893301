import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

if (process.env.CLIENT) {
  require('./FocusTarget.scss'); // eslint-disable-line global-require
}

const FocusTarget = forwardRef(({ children, className, ...props }, ref) => (
  <div className={classNames('focus-target', className)} tabIndex={-1} ref={ref} {...props}>
    {children}
  </div>
));

FocusTarget.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

FocusTarget.defaultProps = {
  children: undefined,
  className: undefined,
};

export default FocusTarget;
