import React from 'react';

export default () => (
  <div className="no-recipes gel-wrap">
    <div className="gel-layout">
      <div className="gel-layout__item">
        Start adding things wherever you see...
        <p className="recipe-actions-text recipe-actions-text--button my-favourites-recipes__start-adding">
          <button
            name="favouritesLink"
            disabled
            className="recipe-actions-link__button recipe-actions-link__button--dark-bg"
            id="add-to-favourites-button"
            type="button"
          >
            <span
              name="favouritesLinkIcon"
              className="recipe-actions__add-favourites-icon recipe-actions__add-favourites-icon--dark-bg"
            />
            Add to favourites
          </button>
        </p>
      </div>
    </div>
  </div>
);
